/* eslint-disable prettier/prettier */
import React from 'react';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { addOrEditOrDeleteUser } from '../../../services/userService';
import ReusableForm from '../../../components/common/customForm';
import {
  USER_DEFAULT_GROUPS,
  USER_DEFAULT_ROLES,
} from '../../../components/initial/defaultData';
import {
  handleErrorNotification,
  handleSuccessNotification,
} from '../../utils/notificationHelpers';
import CONSTANTS, { FORM_PLACEHOLDER_VALUES, FORM_MESSAGES, FORM_LABEL_NAMES } from '../../../constants/constants';

export const userValidationSchema = Yup.object({
  userName: Yup.string()
    .required(FORM_MESSAGES.USER_NAME_REQUIRED)
    .min(4, FORM_MESSAGES.LENGTH_FOUR_REQUIRED)
    .trim(),
  firstName: Yup.string().required(FORM_MESSAGES.FIRST_NAME_REQUIRED),
  lastName: Yup.string().required(FORM_MESSAGES.LAST_NAME_REQUIRED),
  mobileNumber: Yup.string()
    .matches(/^\d{10}$/, FORM_MESSAGES.PHONE_TEN_DIGITS_REQUIRED)
    .length(10)
    .required(FORM_MESSAGES.MOBILE_NUMBER_REQUIRED),
  emailId: Yup.string()
    .email(FORM_MESSAGES.ENTER_VALID_EMAIL)
    .required(FORM_MESSAGES.EMAIL_REQUIRED),
  clientName: Yup.string(),
  department: Yup.string(),
  groups: Yup.array(),
  roles: Yup.array(),
});

const initialUserValues = {
  userName: '',
  firstName: '',
  lastName: '',
  mobileNumber: '',
  emailId: '',
  clientName: '',
  department: '',
  groups: USER_DEFAULT_GROUPS,
  roles: USER_DEFAULT_ROLES,
};

const userFields = [
  {
    name: 'userName',
    label: FORM_LABEL_NAMES.USERNAME,
    type: 'text',
    placeholder: FORM_PLACEHOLDER_VALUES.USERNAME,
    required: true,
  },
  {
    name: 'firstName',
    label: FORM_LABEL_NAMES.FIRSTNAME,
    type: 'text',
    placeholder: FORM_PLACEHOLDER_VALUES.FIRSTNAME,
    required: true,
  },
  {
    name: 'lastName',
    label: FORM_LABEL_NAMES.LASTNAME,
    type: 'text',
    placeholder: FORM_PLACEHOLDER_VALUES.LASTNAME,
    required: true,
  },
  {
    name: 'mobileNumber',
    label: FORM_LABEL_NAMES.MOBILE_NUMBER,
    type: 'text',
    placeholder: FORM_PLACEHOLDER_VALUES.MOBILE_NUMBER,
    required: true,
  },
  {
    name: 'emailId',
    label: FORM_LABEL_NAMES.EMAIL,
    type: 'email',
    placeholder: FORM_PLACEHOLDER_VALUES.EMAIL,
    required: true,
  },
  {
    name: 'department',
    label: FORM_LABEL_NAMES.JOB_TITLE,
    type: 'text',
    placeholder: FORM_PLACEHOLDER_VALUES.JOB_TITLE,
    required: false,
  },
];

export const userFormPayload: any = (values) => ({
  userName: values?.userName,
  firstName: values?.firstName,
  lastName: values?.lastName,
  mobileNumber: values?.mobileNumber,
  emailId: values?.emailId,
  clientName: values?.clientName,
  department: values?.department,
  groups: values?.groups,
  roles: values?.roles,
});

interface CreateUserProps {
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  show: boolean;
}

const CreateUser: React.FC<CreateUserProps> = ({ show, setShow }) => {
  const dispatch = useDispatch();

  const handleSubmit = async (values: any, setSubmitting: any) => {
    postFormValues(values);
    setSubmitting(true);
  };

  const postFormValues = (values: any) => {
    const postData = userFormPayload(values);
    handlePostApiRequest(postData);
  };

  const handlePostApiRequest = async (data) => {
    const response: any = await addOrEditOrDeleteUser('add', null, data);

    if (response?.success) {
      setShow(false);
      handleSuccessNotification(dispatch, response?.message);
    } else {
      setShow(false);
      handleErrorNotification(dispatch, response?.message);
    }
  };

  return (
    <ReusableForm
      id={'createUserForm'}
      open={show}
      onClose={() => setShow(false)}
      initialValues={initialUserValues}
      validationSchema={userValidationSchema}
      onSubmit={handleSubmit}
      fields={userFields}
      title={'Register User'}
      buttonLabel={'Add User'}
    />
  );
};

export default CreateUser;
