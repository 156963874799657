/* eslint-disable prettier/prettier */
import moment from 'moment';
import * as Yup from 'yup';
import ReusableForm from '../../../components/common/customForm';
import { addOrEditOrDeleteClient } from '../../../services/clientServices';
import { useDispatch } from 'react-redux';
import { handleErrorNotification, handleSuccessNotification } from '../../utils/notificationHelpers';
import { FORM_LABEL_NAMES, FORM_MESSAGES } from '../../../constants/constants';

const initialValues = {
  client_name: '',
  business_owner: '',
  business_owner_email: '',
  business_owner_phone: '',
  technical_owner: '',
  technical_owner_email: '',
  technical_owner_phone: '',
  onboarding_date: moment().format('DD/MM/YYYY'),
  address: '',
  gstin: '',
  pan: '',
}

export const clientValidationSchema = Yup.object().shape({
    client_name: Yup.string().required(FORM_MESSAGES.CLIENT_NAME_REQUIRED).trim(),
    business_owner: Yup.string()
      .matches(
        /^(?=.*[A-Za-z])[\w ]+$/,
        FORM_MESSAGES.BUSINESS_OWNER_LETTERS_MESSAGE
      )
      .required(FORM_MESSAGES.BUSINESS_OWNER_NAME_REQUIRED),
    business_owner_email: Yup.string()
      .email(FORM_MESSAGES.ENTER_VALID_EMAIL)
      .required(FORM_MESSAGES.BUSINESS_OWNER_EMAIL_REQUIRED),
    business_owner_phone: Yup.string()
      .notRequired()
      .matches(/^\d{0,10}$/, FORM_MESSAGES.PHONE_NUMER_TEN_DIGITS)
      .max(10, FORM_MESSAGES.MAXIMUM_PHONE_NUMBER_LENGTH),
    technical_owner: Yup.string()
      .matches(
        /^(?=.*[A-Za-z])[\w ]+$/,
       FORM_MESSAGES.TECHNICAL_OWNER_LETTERS_MESSAGE
      )
      .required(FORM_MESSAGES.TECHNICAL_OWNER_NAME_REQUIRED),
    technical_owner_email: Yup.string()
      .email(FORM_MESSAGES.ENTER_VALID_EMAIL)
      .required(FORM_MESSAGES.TECHNICAL_OWNER_EMAIL_REQUIRED),
    technical_owner_phone: Yup.string()
      .notRequired()
      .matches(/^\d{0,10}$/, FORM_MESSAGES.PHONE_NUMER_TEN_DIGITS)
      .max(10, FORM_MESSAGES.MAXIMUM_PHONE_NUMBER_LENGTH),
    address: Yup.string(),
    gstin: Yup.string(),
    pan: Yup.string(),
})

export const formPayload: any = (values: any) => ({
  client_name: values?.client_name,
  business_owner: values?.business_owner,
  business_owner_email: values?.business_owner_email,
  business_owner_phone: values?.business_owner_phone,
  technical_owner: values?.technical_owner,
  technical_owner_email: values?.technical_owner_email,
  technical_owner_phone: values?.technical_owner_phone,
  address: values?.address,
  gstin: values?.gstin,
  pan: values?.pan,
  ...(values?.onboarding_date && { onboarding_date: values?.onboarding_date }), 
});


export const clientFields = [
  {
    name: 'client_name',
    label: FORM_LABEL_NAMES.CLIENT_NAME,
    type: 'text',
    placeholder: FORM_LABEL_NAMES.CLIENT_NAME,
    required: true
  },
  {
    name: 'business_owner',
    label: FORM_LABEL_NAMES.BUSINESS_OWNER,
    type: 'text',
    placeholder: FORM_LABEL_NAMES.BUSINESS_OWNER,
    required: true
  },
  {
    name: 'business_owner_email',
    label: FORM_LABEL_NAMES.BUSINESS_OWNER_EMAIL,
    type: 'text',
    placeholder:  FORM_LABEL_NAMES.BUSINESS_OWNER_EMAIL,
  },
  {
    name: 'business_owner_phone',
    label: FORM_LABEL_NAMES.BUSINESS_OWNER_PHONE,
    type: 'text',
    placeholder: FORM_LABEL_NAMES.BUSINESS_OWNER_PHONE,
  },
  {
    name: 'technical_owner',
    label: FORM_LABEL_NAMES.TECHNICAL_OWNER,
    type: 'text',
    placeholder: FORM_LABEL_NAMES.TECHNICAL_OWNER,
    required: true
  },
  {
    name: 'technical_owner_email',
    label: FORM_LABEL_NAMES.TECHNICAL_OWNER_EMAIL,
    type: 'text',
    placeholder:  FORM_LABEL_NAMES.TECHNICAL_OWNER_EMAIL, 
    required: true
  },
  {
    name: 'technical_owner_phone',
    label:  FORM_LABEL_NAMES.TECHNICAL_OWNER_PHONE,
    type: 'text',
    placeholder: FORM_LABEL_NAMES.TECHNICAL_OWNER_PHONE,
  },
  {
    name: 'address',
    label: FORM_LABEL_NAMES.ADDRESS,
    type: 'text',
    placeholder: FORM_LABEL_NAMES.ADDRESS,
    required: true
  },
  {
    name: 'gstin',  
    label: FORM_LABEL_NAMES.GSTIN,
    type: 'text',
    placeholder: FORM_LABEL_NAMES.GSTIN,
  },
  {
    name: 'pan',
    label: FORM_LABEL_NAMES.PAN,
    type: 'text',
    placeholder: FORM_LABEL_NAMES.PAN,
  }
  
];

interface CreateClientProps {
  id: string;
  show: boolean;
  setShow: any;
  handleGetAllClients: any;
}


const CreateClient: React.FC<CreateClientProps> = ({id,show,setShow, handleGetAllClients}) => {

  const dispatch = useDispatch();

  const handlePostApiRequest = async (data) => {
    const response = await addOrEditOrDeleteClient('add-client', null, data);

    if (response?.success) {
      setShow(false);
      handleSuccessNotification(dispatch,response?.message)
      handleGetAllClients();
    } else {
      setShow(false);
      handleErrorNotification(dispatch,response?.message)
    }
  };

  const postFormValues = (values: any) => {
    const postData = formPayload(values);
    handlePostApiRequest(postData);
  };
  const handleSubmit = async (values: any, setSubmitting: any) => {
    postFormValues(values);
    setSubmitting(false);

  };



  return (
    <ReusableForm
      id={id}
      open={show}
      initialValues={initialValues}
      validationSchema={clientValidationSchema}
      fields={clientFields}
      title="Register Client"
      onClose={() => setShow(false)}
      buttonLabel="Add Client"
      onSubmit={handleSubmit}


    />
  );
};

export default CreateClient;
