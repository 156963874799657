import {
  ATTACK_NOTIFICATIONS_LIST,
  PUSH_NOTIFICATION,
} from '../../constants/actions';

const initialNotificationState = {
  notification: {},
  notificationsData: {},
};

export const notificationReducer = (
  state = initialNotificationState,
  action
) => {
  if (action?.type === PUSH_NOTIFICATION) {
    return { ...state, notification: action?.payload };
  } else if (action?.type === ATTACK_NOTIFICATIONS_LIST) {
    return { ...state, notificationsData: action?.payload };
  }
  return state;
};
