export const TEST_CONSTANTS = {
  RESIZE: 'resize',
  ADD_EVENT_LISTENER: 'addEventListener',
  REMOVE_EVENT_LISTENER: 'removeEventListener',
  COLOR: 'color',
  HEIGHT: 'height',
  WIDTH: 'width',
  INNER_WIDTH: 'innerWidth',
  IMG_ROLE: 'img',
  IMG_SRC: 'src',
  ANCHOR_ELEMENT: 'a',
  LINK_HREF: 'href',
  ID_ATTRIBUTE: 'id',
  ROLE_ATTR: 'role',
  ALERT_ROLE: 'alert',
  BUTTON_ROLE: 'button',
  NAVIGATION_ROLE: 'navigation',
  LISTBOX_ROLE: 'listbox',
  TAB_ROLE: 'tab',
  ARIA_LABEL: 'aria-label',
  CHECKBOX_ROLE: 'checkbox',
  COMBOBOX_ROLE: 'combobox',
  TEXTBOX_ROLE: 'textbox',
  DIALOG: 'dialog',
  OPTION_ROLE: 'option',
  TEST_NOTIFICATION_MESSAGE: 'Test Notification Message',
  TERMS_AND_CONDITIONS_TEXT: 'Terms and conditions\n© CYBERSOPHY 2023',
  EULA_TITLE: 'End-User License Agreement (EULA)',
  EULA_CHECKBOX_LABEL:
    'I have read and agree to the End-User License Agreement.',
  FETCH_ERROR: 'Fetch error',
  MAPPING_FOR_USER_ID_NOT_FOUND_MSG: 'Mapping for user_id not found',
  API_ERROR_MSG: 'API Error',
  SESSION_STORAGE_TEXT: 'sessionStorage',
  LOCATION_TEXT: 'location',
  NOTIFICATION_MESSAGE: 'Notification Message',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  UNKNOWN_ACTION: 'UNKNOWN_ACTION',
  SOME_ACCESS_TOKEN: 'someAccessToken123',
  LOADING_TEXT: 'Loading...',
  GET_ITEM_TEXT: 'getItem',
  SET_ITEM_TEXT: 'setItem',
  IMG_PNG_TYPE: 'image/png',
  TEXT_CSV_TYPE: 'text/csv',
  TEXT_PLAIN_TYPE: 'text/plain',
  AVATAR_PNG_NAME: 'avatar.png',
  IMAGE_UPLOAD_SUCCESS: 'Image uploaded successfully',
  UPLOAD_IMG_FAILED: 'Upload failed',
  DOMAIN_ADD_SUCCESS_MSG: 'Domain added successfully',
  FAILED_TO_ADD_DOMAIN_MSG: 'Failed to add domain',
  CLIENT_DELETE_SUCCESS_MSG: 'Client deleted successfully',
  ERROR_ADDING_CLIENT_MSG: 'Error adding client',
  CLIENT_ADD_SUCCESS_MSG: 'Client added successfully',
  CLIENT_EDIT_SUCCESS_MSG: 'Client updated successfully',
  ERROR_EDITING_CLIENT_MSG: 'Error editing client',
  DELETE_DOMAIN_FAILURE_MSG: 'Delete domain failed',
  DELETE_DOMAIN_SUCCESS_MSG: 'Deleted domain successfully',
  UPDATE_CLIENT_FAILURE_MSG: 'Update Client failed',
  DOMAIN_UPDATE_SUCCESS_MSG: 'Domain updated successfully',
  FAILED_TO_UPDATE_DOMAIN_MSG: 'Failed to update domain',
  USER_ADD_SUCCESS_MSG: 'User added successfully',
  FAILED_TO_ADD_USER_MSG: 'Failed to add user',
  USER_UPDATE_SUCCESS_MSG: 'User updated successfully',
  USER_DELETE_SUCCESS_MSG: 'User deleted successfully',
  DELETE_USER_FAILURE_MSG: 'Delete user failed',
  USER_UPDATE_FAILURE_MSG: 'Failed to update user',
  UPDATE_CLIENT_USER_MAPPING_FAILURE_MSG: 'Update Client-User mapping failed',
  MAP_CLIENT_USER_FAILURE_MSG: 'Failed to map client to user',
  PLEASE_LOGIN_AGAIN_MWG: 'Please login again...',
  ERROR_FETCHING_CRS_RULES_MSG: 'Error fetching CRS rules:',
  CRS_UPLOAD_FAILURE_MSG: 'CRS upload failed',
  FAILED_TO_PERFORM_OPERATION_MSG: 'Failed to perform action Error: API Error',
  FILE_UPLOAD_SUCCESS_MSG: 'File uploaded successfully',
  RULE_DELETE_SUCCESS_MSG: 'Rule deleted successfully',
  RULE_ADDED_SUCCESS_MSG: 'Rule added successfully',
  RULE_UPDATED_SUCCESS_MSG: 'Rule updated successfully',
  RULE_ADDED_OR_UPDATED_SUCCESS_MSG: 'Rule added/updated successfully',
  RULE_DELETE_FAILURE_MSG: 'Rule delete failed',
  LOADING_PLEASE_WAIT_TEXT: 'Loading please wait',
  LOADING_COLOR: '#025e5e',
  START_DATE_TEXT: 'Start Date',
  END_DATE_TEXT: 'End Date',
  UNKNOWN_ROUTE: 'some-unknown-route',
  PAGE_NOT_FOUND_TEXT: 'page not found',
  DATE_TIME_FORMAT: 'YYYY-MM-DDTHH:mm:ss[Z]',
  DATE_FORMAT: 'DD/MM/YYYY',
  DATE_TIME_WITHOUT_SECOND_FORMAT: 'DD/MM/YYYY HH:mm',
  INVALID_JSON_MSG: 'Invalid JSON',
  BLOB_ERROR_MSG: 'Blob error',
  DOCUMENT_DELETE_MSG: 'Document deleted',
  PROFILE_IMG_UPLOADED_MSG: 'Profile image uploaded',
  ERROR_FETCHING_USERS_MSG: 'Error fetching users',
  ERROR_FETCHING_LOGGED_IN_USER_MSG: 'Error fetching logged-in user',
  TEST_IP_ADDRESS: '192.0.2.1',
  SIGN_UP_NOTIFICATION: 'Signup Notification',
};
