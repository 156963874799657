/* eslint-disable prettier/prettier */
import { IconButton } from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import CONSTANTS, {
  DATE_SLASH_FORMAT,
  FORM_LABEL_NAMES,
  FORM_MESSAGES,
  FORM_PLACEHOLDER_VALUES,
  URL_PATTERN,
} from '../../../constants/constants';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import * as Yup from 'yup';
import moment from 'moment';
import { addOrEditOrDeleteClient } from '../../../services/clientServices';
import ReusableForm from '../../../components/common/customForm';
import { makeStyles } from '@material-ui/core';
import {
  handleErrorNotification,
  handleSuccessNotification,
} from '../../utils/notificationHelpers';
import { convertStringIntoArray } from '../../utils/processData';

interface AddDomainToClientProps {
  clientId: string;
  handleGetPaginatedClients?: any;
  initialValues?: any;
  isEditing?: boolean;
  onClose?: any;
  anchorEl?: HTMLElement | null;
  handleAddDomainBtnClick?: any;
}

const domainValidationSchema = Yup.object().shape({
  domain_name: Yup.string().required(FORM_MESSAGES.DOMAIN_NAME_REQUIRED),
  domain_url: Yup.string()
    .matches(URL_PATTERN, FORM_MESSAGES.URL_INVALID)
    .required(FORM_MESSAGES.DOMAIN_URL_REQUIRED
    ),
  db_name: Yup.string(),
  ip_address: Yup.string().required(FORM_MESSAGES.IP_ADDRESS_REQUIRED),
  current_mode: Yup.string().required(FORM_MESSAGES.MODE_REQUIRED),
  hosting_service_name: Yup.string().required(
    FORM_MESSAGES.HOSTING_SERVICE_REQUIRED
  ),
  dns_mapped: Yup.boolean().required(FORM_MESSAGES.DNS_MAPPING_REQUIRED),
  mapped_on: Yup.string().required(FORM_MESSAGES.MAPPED_ON_DATE_REQUIRED),
  domain_emails: Yup.string()
    .required(FORM_MESSAGES.ONE_DOMAIN_EMAIL_REQUIRED)
    .test(FORM_MESSAGES.VALID_EMAILS, FORM_MESSAGES.INVALID_EMAILS, (value) => {
      if (!value) return false; // Ensure the field is not empty
      return value
        .split(',')
        .every((email) => Yup.string().email().isValidSync(email.trim()));
    }),
  email_notifications: Yup.boolean(),
});
const domainFields = [
  {
    name: 'domain_name',
    label: FORM_LABEL_NAMES.DOMAIN_NAME,
    type: 'text',
    placeholder: FORM_PLACEHOLDER_VALUES.DOMAIN_NAME,
    required: true,
  },
  {
    name: 'domain_url',
    label: FORM_LABEL_NAMES.DOMAIN_URL,
    type: 'text',
    placeholder: FORM_PLACEHOLDER_VALUES.DOMAIN_URL,
    required: true,
  },
  {
    name: 'db_name',
    label: FORM_LABEL_NAMES.DATABASE_NAME,
    type: 'text',
    placeholder: FORM_PLACEHOLDER_VALUES.DATABASE_NAME,
    required: false,
  },
  {
    name: 'ip_address',
    label: FORM_LABEL_NAMES.IP_ADDRESS,
    type: 'text',
    placeholder: FORM_PLACEHOLDER_VALUES.IP_ADDRESS,
    required: true,
  },
  {
    name: 'current_mode',
    label: FORM_LABEL_NAMES.CURRENT_MODE,
    type: 'text',
    placeholder: FORM_PLACEHOLDER_VALUES.TRANSPARENT_MODE,
    disabled: true,
  },
  {
    name: 'hosting_service_name',
    label: FORM_LABEL_NAMES.HOSTING_SERVICE_NAME,
    type: 'text',
    placeholder: FORM_PLACEHOLDER_VALUES.HOSTING_SERVICE,
  },
  { name: 'dns_mapped', label: FORM_LABEL_NAMES.DNS_MAPPED, type: 'checkbox' },
  {
    name: 'email_notifications',
    label: FORM_LABEL_NAMES.EMAIL_NOTIFICATION,
    type: 'checkbox',
  },
  {
    name: 'mapped_on',
    label: FORM_LABEL_NAMES.MAPPED_ON,
    type: 'text',
    placeholder: FORM_PLACEHOLDER_VALUES.MAPPED_ON_DATE,
  },
  {
    name: 'domain_emails',
    label: FORM_LABEL_NAMES.DOMAIN_EMAILS,
    type: 'multi-input',
    placeholder: FORM_PLACEHOLDER_VALUES.DOMAIN_EMAILS,
  },
];

const useStyles = makeStyles((theme) => ({
  iconButton: {
    fontSize: '1rem',
    [theme.breakpoints.up('lg')]: {
      fontSize: '2rem',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '3.2rem',
    },
  },
}));

const AddDomainToClient: React.FC<AddDomainToClientProps> = ({
  clientId,
  handleGetPaginatedClients,
}) => {
  const [isPopoverOpen, setPopoverOpen] = useState<boolean>(false);

  const handleAddCircleButtonClick = () => {
    setPopoverOpen(true);
  };

  const handleClosePopover = () => {
    setPopoverOpen(false);
  };

  const dispatch = useDispatch();
  const classes = useStyles();

  const initialValues = {
    client_id: clientId,
    domain_name: '',
    domain_url: '',
    db_name: '',
    ip_address: '',
    current_mode: 'Off',
    hosting_service_name: CONSTANTS.DEFAULT_HOSTING_SERVICE,
    dns_mapped: true,
    mapped_on: moment().format(DATE_SLASH_FORMAT),
    domain_emails: [],
    email_notifications: true,
  };

  const formPayload = (values: any) => ({
    domain_name: values?.domain_name,
    domain_url: values?.domain_url,
    db_name: values?.db_name,
    ip_address: values?.ip_address,
    current_mode: values?.current_mode,
    hosting_service_name: values?.hosting_service_name,
    dns_mapped: values?.dns_mapped,
    mapped_on: values?.mapped_on,
    client_id: values?.client_id,
    domain_emails: convertStringIntoArray(values?.domain_emails),
    email_notifications: values?.email_notifications,
  });

  const postFormValues = (values: any) => {
    const postData = formPayload(values);
    handlePostApiRequest(postData);
  };

  const handlePostApiRequest = async (data) => {
    const response: any = await addOrEditOrDeleteClient(
      'add-domain',
      null,
      data
    );

    if (response?.success) {
      handleClosePopover();
      handleGetPaginatedClients();
      handleSuccessNotification(dispatch, response?.message);
    } else {
      handleClosePopover();
      handleErrorNotification(dispatch, response?.message);
    }
  };

  const handleSubmit = async (values: any, setSubmitting: any) => {
    postFormValues(values);
    setSubmitting(false);
  };

  return (
    <>
      <IconButton onClick={handleAddCircleButtonClick}>
        <AddCircleOutlineIcon className={classes.iconButton} />
      </IconButton>
      <ReusableForm
        id="addDomainForm"
        open={isPopoverOpen}
        onClose={handleClosePopover}
        initialValues={initialValues}
        validationSchema={domainValidationSchema}
        onSubmit={handleSubmit}
        fields={domainFields}
        title="Add Domain to Client"
        buttonLabel={CONSTANTS.ADD_URL_BTN_TEXT}
      />
    </>
  );
};

export default AddDomainToClient;
