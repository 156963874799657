/* eslint-disable prettier/prettier */
import React from 'react';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { NotificationProps } from './type';

const Notification: React.FC<NotificationProps> = ({
  className,
  isOpen,
  type,
  message,
  handleClose,
  position = { vertical: 'bottom', horizontal: 'center' },
  ...rest
}: NotificationProps) => {
  return (
    <Snackbar
      data-testid="closeNotification"
      className={className}
      open={isOpen}
      autoHideDuration={3000}
      anchorOrigin={position}
      {...rest}
      onClose={handleClose}
    >
      <Alert
        severity={type ? type : 'info'}
        variant="filled"
        onClose={handleClose}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default Notification;
