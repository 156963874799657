const CONSTANTS = {
  WELCOME_MSG: 'Cyber Wasp',
  COMPONENT_LIST_MSG: 'component_list_msg',
  DEFAULT_MSG: 'default_msg',
  PROFILE_MSG: 'profile_msg',
  SETTINGS: 'settings',
  PROFILE: 'profile',
  LOGOUT: 'Logout',
  SIGN_UP: 'signup',
  THEME: 'theme',
  SXP_TITLE: 'Welcome to TSF React base project',
  SXP_SUBTITLE: 'Service eXperience Platform',
  SXP_DEFAULT_MSG: 'Hi, Welcome To React Template Project',
  VERSION_ID: 'WORKING',
  COULDNT_UPLOAD_DOC: 'Could not upload Document',
  SUCCESS: 'success',
  ERROR: 'error',
  INFO: 'info',
  BUSINESS_USER: 'business-user',
  SELECT_START_END_DATE: 'Select start date and end date',
  USER_EMAIL: 'user-email',
  WELCOME: 'Welcome back',
  FIRST_NAME: 'first-name',
  LAST_NAME: 'last-name',
  USER_ID: 'user-id',
  REACT_TOKEN: 'react-token',
  USER_TYPE: 'user-type',
  COUNTRY: 'country',
  I18NEXTLNG: 'i18nextLng',
  IS_ADMIN_USER: 'isWafAdmin',
  WAF_ADMIN: 'wafAdmin',
  IS_WAF_ADMIN_ROLE: 'waf-admin',
  DOMAIN_OBJ: 'domainObj',
  TIME_DURATION: 'duration',
  EULA_ACCEPTANCE: 'is_accepted_eula',
  ACCEPTED_EULA_VERSION: 'ACCEPTED_EULA_VERSION',
  AWGMENT_USER_ID: 'AWGMENT_USER_ID',
  KC_REALM_NAME: 'KC_REALM_NAME',
  KC_AUTH_URL: 'KC_AUTH_URL',
  KC_CLIENT_ID: 'KC_CLIENT_ID',
  GATEWAY_URL: 'GATEWAY_URL',
  AWGMENT_API: 'AWGMENT_API',
  LOGO_URL: 'LOGO_URL',
  EULA_DOC_ID: 'EULA_DOC_ID',
  DMS_DOC_ID: 'DMS_DOC_ID',
  PUBLIC_URL: 'PUBLIC_URL',
  DOWNLOADED_LOGS_FILE_NAME: 'cyberwasp_logs.pdf',
  IS_FROM_TO: 'fromTo',
  NAVIGATE_TO_DASHBOARD: '/',
  CUSTOM_LABEL: 'custom',
  TODAY: 'today',
  ASIA_KOLKATA_ZONE: 'Asia/Kolkata',
  CUSTOM_DURATION: 'Custom Duration',
  END_DATE_ERROR: 'Please select the end date also',
  UPDATED_EULA_ACCEPTANCE_MSG: 'Updated the EULA Acceptance successfully',
  NOT_FOUND: 'not found',
  MAPPING_FOR_USER_ID: 'Mapping for user_id',
  FAILED_EULA_MSG: 'Failed to update EULA',
  FAILED_TO_FETCH_EULA_MSG: 'Failed to fetch EULA text',
  DOWNLOAD_FOR_LESS_OR_EQUAL_TO_7_DAYS:
    'You can download logs for less than or equal to 7 days',
  START_DATE_PRIOR_TO_END_DATE: 'Start date should be prior to end date',
  PLEASE_WAIT_MESSAGE: 'Please wait...',
  PDF_DOWNLOAD_FAILURE_MSG:
    'Failed to download the logs or No data found for the given period of time',
  PDF_DOWNLOAD_SUCCESS_MSG: 'Downloaded the logs successfully',
  DELETE_CLIENT_FAILURE_MSG: 'Failed to delete the client',
  ON_AUTH_SUCCESS: 'onAuthSuccess',
  ON_TOKEN_EXPIRED: 'onTokenExpired',
  ON_AUTH_LOGOUT: 'onAuthLogout',
  MODE_ON: 'On',
  MODE_OFF: 'Off',
  BLOCK_MODE: 'Block Mode',
  TRANSPARENT_MODE: 'Transparent Mode',
  ENTER_KEY: 'Enter',
  FORBIDDEN_STATUS_CODE: 403,
  ERROR_FETCHING_USER_DETAILS_MSG: 'Error fetching user details',
  USER: 'user',
  IS_DELETE_CLIENT: 'Delete Client?',
  DELETE_CLIENT_CONFIRMATION_MSG:
    'Are you sure you want to delete this Client?',
  IS_DELETE_DOMAIN: 'Delete domain?',
  DELETE_DOMAIN_CONFIRMATION_MSG:
    'Are you sure you want to delete this domain?',
  IS_DELETE_RULE: 'Delete Rule?',
  DELETE_RULE_CONFIRMATION_MSG: 'Are you sure you want to delete this rule?',
  IS_DELETE_USER: 'Delete User?',
  DELETE_USER_CONFIRMATION_MSG: 'Are you sure you want to delete this user?',
  CANCEL_BTN_TEXT: 'Cancel',
  CONFIRM_BTN_TEXT: 'Confirm',
  OK_TEXT: 'OK',
  SUBMIT_BTN_TEXT: 'Submit',
  UPLOAD_BTN_TEXT: 'Upload',
  DECLINE_BTN_TEXT: 'Decline',
  ACCEPT_BTN_TEXT: 'Accept',
  FAILED_TO_LOAD_EULA_MSG: 'Failed to load EULA text.',
  TRUE_TEXT: 'true',
  REFRESH_BTN_TEXT: 'Refresh',
  DOWNLOAD_BTN_TEXT: 'Download',
  SEARCH_BY_SOURCE_IP_OR_URI_TEXT: 'Search by Source IP or URI',
  DEFAULT_HOSTING_SERVICE: 'AWS',
  ADD_CLIENT_BTN_TEXT: 'Add Client',
  ADD_USER_BTN_TEXT: 'Add User',
  UPDATE_CLIENT_BTN_TEXT: 'Update',
  SERVER_ERROR_MSG: 'Error communicating with server',
  INTERNAL_SERVER_ERROR_MSG: 'Internal server error',
  PLEASE_LOGIN_MSG: 'Please login...',
  INVALID_ACTION_TYPE_MSG: 'Invalid action type',
  DONE_BTN_TEXT: 'Done',
  SINGLE_DAY_TEXT: 'Single Day',
  FROM_TO_TEXT: 'From-To',
  OK_BTN_TEXT: 'OK',
  APPLY_BTN_TEXT: 'Apply',
  CLEAR_FILTERS_BTN_TEXT: 'Clear Filters',
  CLOSE_BTN_TEXT: 'Close',
  DEFAULT_SEARCH_PLACEHOLDER: 'Search...',
  NO_DATA_FOUND_TEXT: 'No Data Found',
  FILTER_BTN_TEXT: 'Filter',
  NOT_AVAILABLE_TEXT: 'Not Available',
  IN_CRORES_TEXT: 'in crores',
  IN_LAKHS_TEXT: 'in lakhs',
  IN_THOUSANDS_TEXT: 'in thousands',
  IN_HUNDREDS_TEXT: 'in hundreds',
  WHEEL_TEXT: 'wheel',
  DOUBLE_CLICK_TEXT: 'dblclick',
  OBJECT_TYPE: 'object',
  ATTACK_TEXT: 'attack',
  INVALID_DATE_TEXT: 'Invalid Date',
  DATE_SLASH_FORMAT: 'DD/MM/YYYY',
  DATE_FORMAT: 'DD-MM-YYYY',
  ADD_URL_BTN_TEXT: 'Add URL',
  ACTIONS_TEXT: 'actions',
  FAILED_TO_PERFORM_ACTION_MSG: 'Failed to perform action',
  STRING_TYPE: 'string',
  BLOCKED_STATUS: 'Blocked',
  ALLOWED_STATUS: 'Allowed',
  ATTACKS_NOTIFICATIONS: 'Notifications',
  DOMAIN_OBJ_NOTIFICATION: 'domainObjNotification',
  DATE_FILTER: 'FilterByDate',
  DATE_FILTER_BTN_TEXT: 'Date Filter',
  ADD_DOMAIN_TO_CLIENT: 'Add Domain to Client',
};

export default CONSTANTS;

export const URL_PATTERN =
  /^(?:https?:\/\/)?(?:(?:[a-z0-9-]+\.)+[a-z]{2,}|localhost|(?:\d{1,3}\.){3}\d{1,3})(?::\d{1,5})?(?:\/\S*)?$/i;

// eslint-disable-next-line quotes
export const DATE_TIME_FORMAT = "yyyy-MM-dd'T'HH:mm:ss'Z'";
export const DATE_SLASH_FORMAT = 'DD/MM/YYYY HH:mm:ss';

export const MENU_PATHS = {
  DASHBOARD: 'dashboard',
  CLIENTS: 'clients',
  USERS: 'users',
  CORE_RULE_SET: 'core-rule-set',
  USER_PROFILE: 'user-profile',
  LOGOUT: '/logout',
  NOTIFICATIONS: 'notifications',
};

export const DURATIONS = {
  HOURS_12: '12h',
  HOURS_24: '24h',
  WEEK: 'week',
  MONTH: 'month',
  YEAR: 'year',
  CUSTOM: 'custom',
};

export const DATE_TIME_FORMATS = {
  FORMAT_1: 'YYYY-MM-DDTHH:mm:ssZ',
  FORMAT_2: 'YYYY-MM-DD HH:mm:ss',
  FORMAT_3: 'YYYY-MM-DD hh:mm:ss A',
  FORMAT_4: 'YYYY-MM-DDTHH:mm:ss[Z]',
};

export const MAP_COLORS = {
  TRAFFIC_STOP: '#3F93FF',
  TRAFFIC_START: '#A1FF8B',
  ATTACK_START: '#FBDA61',
  ATTACK_STOP: '#FF4B55',
  GLOW: '#E4E7EC',
  TRAFFIC_GRADIENT: 'linear-gradient(135deg, #A1FF8B 2.88%, #3F93FF 98.13%)',
  ATTACK_GRADIENT: 'linear-gradient(135deg, #FBDA61 2.88%, #FF4B55 98.13%)',
};

export const WEEKDAY_LABELS = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

export const FORM_MESSAGES = {
  DOMAIN_NAME_REQUIRED: 'Domain name is required',
  DOMAIN_URL_REQUIRED: 'Domain URL is required',
  HOSTING_SERVICE_REQUIRED: 'Hosting service name is required',
  URL_INVALID: 'Invalid URL',
  IP_ADDRESS_REQUIRED: 'IP address is required',
  MODE_REQUIRED: 'Current mode is required',
  DNS_MAPPING_REQUIRED: 'DNS mapped is required',
  MAPPED_ON_DATE_REQUIRED: 'Mapped on date is required',
  ONE_DOMAIN_EMAIL_REQUIRED: 'At least one domain email is required',
  VALID_EMAILS: 'valid-emails',
  INVALID_EMAILS: 'Invalid email(s) provided',
  FIRST_NAME_REQUIRED: 'First Name is Requried',
  LAST_NAME_REQUIRED: 'Last Name is Required',
  EMAIL_REQUIRED: 'Email is Required',
  MOBILE_NUMBER_REQUIRED: 'Mobile Number is Required',
  PASSWORD_REQUIRED: 'Password is Required',
  CONFIRM_PASSWORD_REQUIRED: 'Confirm Password is Required',
  USER_NAME_REQUIRED: 'User Name is Requried',
  LENGTH_FOUR_REQUIRED: 'Minimum length is 4 Characters',
  PHONE_TEN_DIGITS_REQUIRED: 'Mobile Number is Requried',
  ENTER_VALID_EMAIL: 'Please Enter a Valid Email Id',
  NAME_REQUIRED: 'Name is required',
  INVALID_EMAIL: 'Invalid Email',
  CLIENT_NAME_REQUIRED: 'Client Name is Requried',
  BUSINESS_OWNER_LETTERS_MESSAGE: 'Business Owner must contain only letters',
  TECHNICAL_OWNER_LETTERS_MESSAGE: 'Technical Owner must contain only letters',
  BUSINESS_OWNER_NAME_REQUIRED: `Business owner's name is Requried${''}`,
  BUSINESS_OWNER_EMAIL_REQUIRED: `Business owner's email is Requried${''}`,
  TECHNICAL_OWNER_NAME_REQUIRED: `Technical owner's name is Requried${''}`,
  TECHNICAL_OWNER_EMAIL_REQUIRED: `Technical owner's email is Requried${''}`,
  PHONE_NUMER_TEN_DIGITS: 'Phone number must be at most 10 digits',
  MAXIMUM_PHONE_NUMBER_LENGTH: 'Maximum length of phone number is 10 digits',
};

export const FORM_PLACEHOLDER_VALUES = {
  USERNAME: 'Enter user name',
  FIRSTNAME: 'Enter first name',
  LASTNAME: 'Enter last name',
  EMAIL: 'Enter email',
  MOBILE_NUMBER: 'Enter mobile number',
  PASSWORD: 'Enter password',
  CONFIRM_PASSWORD: 'Confirm password',
  JOB_TITLE: 'Enter job title',
  DOMAIN_NAME: 'Enter domain name',
  DOMAIN_URL: 'Enter domain URL',
  IP_ADDRESS: 'Enter IP address',
  TRANSPARENT_MODE: 'Transparent Mode',
  HOSTING_SERVICE: 'Enter hosting service',
  MAPPED_ON_DATE: 'Mapped on date',
  DOMAIN_EMAILS: 'Enter domain emails',
  DATABASE_NAME: 'Enter database name',
};

export const FORM_LABEL_NAMES = {
  NAME: 'Name',
  USERNAME: 'User Name',
  FIRSTNAME: 'First Name',
  LASTNAME: 'Last Name',
  EMAIL: 'Email',
  MOBILE_NUMBER: 'Mobile Number',
  CLIENT_NAME: 'Client Name',
  PASSWORD: 'Password',
  CONFIRM_PASSWORD: 'Confirm Password',
  JOB_TITLE: 'Job Title',
  DOMAIN_NAME: 'Domain Name',
  DOMAIN_URL: 'Domain URL',
  IP_ADDRESS: 'IP Address',
  TRANSPARENT_MODE: 'Transparent Mode',
  HOSTING_SERVICE: 'Hosting Service',
  MAPPED_ON_DATE: 'Mapped on Date',
  DOMAIN_EMAILS: 'Domain Emails',
  CURRENT_MODE: 'Current Mode',
  HOSTING_SERVICE_NAME: 'Hosting Service Name',
  EMAIL_NOTIFICATION: 'Email Notification',
  MAPPED_ON: 'Mapped On',
  DNS_MAPPED: 'DNS Mapped',
  DATABASE_NAME: 'Database Name',
  COUNTRY: 'Country',
  SUBSCRIBE: 'Subscribe',
  BUSINESS_OWNER: 'Business Owner',
  BUSINESS_OWNER_EMAIL: 'Business Owner Email',
  BUSINESS_OWNER_PHONE: 'Business Owner Phone',
  TECHNICAL_OWNER: 'Technical Owner',
  TECHNICAL_OWNER_EMAIL: 'Technical Owner Email',
  TECHNICAL_OWNER_PHONE: 'Technical Owner Phone',
  ADDRESS: 'Address',
  GSTIN: 'GSTIN',
  PAN: 'PAN',
};

export const RULES_CONSTANTS = {
  RULE_ID_LABEL: 'Rule ID',
  CATEGORY_LABEL: 'Category',
  MESSAGE_LABEL: 'Message',
  SEVERITY_LABEL: 'Severity',
  IMPACT_LABEL: 'Impact',
  RECOMMENDATION_LABEL: 'Recommendation',
  RULE_ID_NUMBER_MESSAGE: 'Rule ID must be a number',
  RULE_ID_REQUIRED: 'Rule ID is required',
  CATEGORY_REQUIRED: 'Category is required',
  MESSAGE_REQUIRED: 'Message is required',
  SEVERITY_REQUIRED: 'Severity is required',
  IMPACT_REQUIRED: 'Impact is required',
  RECOMMENDATION_REQUIRED: 'Recommendation is required',
};
