import {
  ATTACK_NOTIFICATIONS_LIST,
  PUSH_NOTIFICATION,
} from '../../constants/actions';

export const pushNotification = (data) => {
  return { type: PUSH_NOTIFICATION, payload: data };
};

export const setNotificationsData = (data) => {
  return { type: ATTACK_NOTIFICATIONS_LIST, payload: data };
};
