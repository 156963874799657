import { SnackbarOrigin } from '../../components/common/notification/type';
import CONSTANTS from '../../constants/constants';
import { pushNotification } from '../../redux/notification/notificationActions';

export const handleErrorNotification = (
  dispatch: any,
  message: string,
  position?: SnackbarOrigin
) => {
  dispatch(
    pushNotification({
      isOpen: true,
      message,
      type: CONSTANTS.ERROR,
      position,
    })
  );
};

export const handleSuccessNotification = (dispatch, message) => {
  dispatch(
    pushNotification({
      isOpen: true,
      message,
      type: CONSTANTS.SUCCESS,
    })
  );
};
