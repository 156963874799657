/* eslint-disable prettier/prettier */

import React from 'react';
import { addOrEditOrDeleteUser } from '../../../services/userService';
import { userFormPayload, userValidationSchema } from './CreateUser';
import ReusableForm from '../../../components/common/customForm';
import { useDispatch } from 'react-redux';
import {
  mapUserAndClient,
  updateClientAndUserMapping,
} from '../../../services/clientServices';
import {
  handleErrorNotification,
  handleSuccessNotification,
} from '../../utils/notificationHelpers';
import CONSTANTS, { FORM_LABEL_NAMES } from '../../../constants/constants';

interface EditUserProps {
  show?: boolean;
  setShow: any;
  selectedUser: any;
  setSelectedUserClient: any;
  clientsList: any;
  selectedUserClient?: any;
  selectedUserData: any;
  handleGetAllUsers: any;
}

const EditUser: React.FC<EditUserProps> = (props: any) => {
  const {
    show,
    setShow,
    selectedUser,
    setSelectedUserClient,
    clientsList,
    selectedUserData,
    handleGetAllUsers,
  } = props;

  const dispatch = useDispatch();

  const initialEditUserValues = {
    userName: selectedUserData?.userName,
    firstName: selectedUserData?.firstName,
    lastName: selectedUserData?.lastName,
    mobileNumber: selectedUserData?.mobileNumber,
    emailId: selectedUserData?.emailId,
    clientName: selectedUserData?.clientName,
    department: selectedUserData?.department,
    groups: selectedUserData?.groups,
    roles: selectedUserData?.roles,
  };

  const handleSubmit = async (values: any, setSubmitting: any) => {
    postFormValues(values);
    setSubmitting(true);
  };

  const postFormValues = async (values: any) => {
    const postData = userFormPayload(values);

    const client: any = clientsList?.filter(
      (item: any) => item?.client_name === postData?.clientName
    )?.[0];
    const isClientUpdated = values?.clientName === selectedUserData?.clientName;

    let success = true;
    let message = '';
    let updatedUserDetails = postData;
    let result;
    if (!isClientUpdated) {
      if (!selectedUserData?.clientName) {
        const clientUserMappingData = {
          client_id: client?.client_id,
          user_id: selectedUser,
        };
        result = await mapUserAndClient(clientUserMappingData);
        updatedUserDetails = { ...postData, clientName: values?.clientName };
      } else {
        const clientUserData = { client_id: client?.client_id };

        result = await updateClientAndUserMapping(selectedUser, clientUserData);
        updatedUserDetails = { ...postData, clientName: client?.client_name };
      }

      success = result?.success;
      message = result?.message;
    }

    if (success) {
      handlePostApiRequest(updatedUserDetails);
      setShow(false);
      handleSuccessNotification(dispatch, message);
    } else {
      setShow(false);
      handleErrorNotification(dispatch, message);
    }
  };

  const handlePostApiRequest = async (data) => {
    const response: any = await addOrEditOrDeleteUser(
      'update',
      selectedUser,
      data
    );
    if (response?.success) {
      setShow(false);
      handleSuccessNotification(dispatch, response?.message);
      handleGetAllUsers();
    } else {
      setShow(false);
      handleErrorNotification(dispatch, response?.message);
    }
  };

  const userEditFields = [
    {
      name: 'userName',
      label: FORM_LABEL_NAMES.USERNAME,
      type: 'text',
      disabled: true,
      required: true,
    },
    { name: 'firstName', label: FORM_LABEL_NAMES.FIRSTNAME, type: 'text', required: true },
    { name: 'lastName', label: FORM_LABEL_NAMES.LASTNAME, type: 'text', required: true },
    {
      name: 'mobileNumber',
      label: FORM_LABEL_NAMES.MOBILE_NUMBER,
      type: 'text',
      required: true,
    },
    {
      name: 'emailId',
      label: FORM_LABEL_NAMES.EMAIL,
      type: 'email',
      disabled: true,
      required: true,
    },
    {
      name: 'clientName',
      label: FORM_LABEL_NAMES.CLIENT_NAME,
      type: 'autocomplete',
      required: true,
      options: clientsList,
      getOptionLabel: (option: any) => option?.client_name || '',
      getValue: (values: any) =>
        clientsList?.find(
          (client: any) => client?.client_name === values?.clientName
        ) || null,
      onChange: (newValue: any, setFieldValue: any) => {
        setFieldValue('clientName', newValue ? newValue?.client_name : '');
      },
      onInputChange: (event: any, newInputValue: string) => {
        setSelectedUserClient(newInputValue);
      },
    },
    { name: 'department', label: 'Job title', type: 'text' },
  ];

  return (
    <ReusableForm
      id={'editUserForm'}
      title={'Edit User'}
      open={show}
      onClose={() => setShow(false)}
      initialValues={initialEditUserValues}
      validationSchema={userValidationSchema}
      onSubmit={handleSubmit}
      fields={userEditFields}
      buttonLabel={'Update'}
    />
  );
};

export default EditUser;
