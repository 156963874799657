/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { InputAdornment, Box } from '@mui/material';
import { ReactComponent as DomainLinkIcon } from '../../assets/icons/svg/carbon_link.svg';

import { useSelector } from 'react-redux';
import CustomSelect from '../../components/common/customSelect';
import DurationPicker from '../../components/common/customDate/DurationPicker';
import { makeStyles } from '@material-ui/core';
import { getSessionStorageItem } from '../../encrypt';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
  },
  durationPickerWrapper: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '70%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '72%',
    },
  },
}));

interface DomainControlProps {
  id: string;
  defaultDomain: any;
  sessionStorageKey?: any;
  setPage: any;
  onDomainChange?: any;
  isDurationPicker?: boolean;
}

const DomainControl: React.FC<DomainControlProps> = ({
  id,
  sessionStorageKey,
  defaultDomain,
  setPage,
  onDomainChange,
  isDurationPicker = true,
}) => {
  const [selectedDomain, setSelectedDomain] = useState<any>({});

  const { domainsByClient } = useSelector((state: any) => state?.domainData);

  useEffect(() => {
    const storedItem = JSON.parse(
      getSessionStorageItem(sessionStorageKey) ?? '{}'
    );
    if (storedItem?.domain_id) {
      setSelectedDomain(storedItem);
    } else {
      setSelectedDomain(defaultDomain);
    }
  }, [defaultDomain, defaultDomain?.domain_id, sessionStorageKey]);

  const handleDomainChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedDomainObj = event?.target?.value;
    setSelectedDomain(JSON.parse(selectedDomainObj));
    onDomainChange(selectedDomainObj);
    setPage(1);
  };

  const classes = useStyles();

  return (
    <Box data-testid={id} id={id} className={classes.container}>
      <CustomSelect
        data-testid={`custom-select-${id}`}
        id={`${id}-textField`}
        options={domainsByClient?.map((eachDomain) => ({
          value: JSON.stringify(eachDomain),
          label: eachDomain?.domain_url,
        }))}
        value={JSON.stringify(selectedDomain)}
        onChange={handleDomainChange}
        startAdornment={
          <InputAdornment position="start">
            <DomainLinkIcon />
          </InputAdornment>
        }
      />
      {isDurationPicker && (
        <Box className={classes.durationPickerWrapper}>
          <DurationPicker setPage={setPage} />
        </Box>
      )}
    </Box>
  );
};

export default DomainControl;
