import {
  DOMAIN_OBJECT,
  DOMAIN_URLS_BY_CLIENT,
  DOMAIN_OBJECT_FOR_NOTIFICATION,
} from '../../constants/actions';

const initialDomainState = {
  domainsByClient: [],
  domain: {},
  domainForNotification: {},
};

export const domainReducer = (state = initialDomainState, action) => {
  switch (action.type) {
    case DOMAIN_URLS_BY_CLIENT:
      return {
        ...state,
        domainsByClient: action?.payload,
      };

    case DOMAIN_OBJECT:
      return {
        ...state,
        domain: action?.payload,
      };
    case DOMAIN_OBJECT_FOR_NOTIFICATION:
      return {
        ...state,
        domainForNotification: action?.payload,
      };

    default:
      return state;
  }
};
