import { debounce } from 'lodash';
import CONSTANTS, { DURATIONS } from '../constants/constants';
import {
  ATTACK_NOTIFICATION_ENDPOINT,
  DOMAINS_BY_CLIENT_ENDPOINT,
  getAttacksInformationEndpoint,
  getAttacksTrendlineEndpoint,
  getCombinedResponseEndpoint,
  getNotificationsListEndpoint,
  getViewDetailsEndpoint,
} from '../constants/endpoints';
import {
  setDomain,
  setDomainForNotification,
  setDomainsByClient,
} from '../redux/domainData/domainActions';
import {
  setAttackInformationData,
  setAttacksTrendlineData,
  setCombinedResponse,
} from '../redux/ui/uiActions';
import { convertIntoArray } from '../screen/utils/convertIntoArray';
import { request } from './request';
import { initialStatsData } from '../components/initial/InitialStatsData';
import {
  setAttackTrendlineLoader,
  setAttacksInformationLoader,
  setCombinedResponseLoader,
} from '../redux/loader/loadingActions';
import dayjs from 'dayjs';
import {
  convertToISTFormat,
  getDaysDifference,
  isValidUTCFormat,
} from '../screen/utils/convertTime';
import { initialUiState } from '../redux/ui/uiReducer';
import { convertKeysToCamelCase } from '../screen/utils/caseConversion';
import { getSessionStorageItem } from '../encrypt';
import { SEVERITY_LEVELS } from '../components/initial/defaultData';
import { setNotificationsData } from '../redux/notification/notificationActions';
import { handleErrorNotification } from '../screen/utils/notificationHelpers';

export const domainsByClientApi = async (dispatch) => {
  try {
    const DOMAINS_BY_CLIENT_URL = `${getSessionStorageItem(
      CONSTANTS.GATEWAY_URL
    )}${DOMAINS_BY_CLIENT_ENDPOINT}`;
    const response: any = await request.get(DOMAINS_BY_CLIENT_URL);

    if (response?.success) {
      dispatch(setDomain(response?.data?.[0]));
      dispatch(setDomainForNotification(response?.data?.[0]));
      dispatch(setDomainsByClient(response?.data));
    } else {
      dispatch(setDomain({}));
      dispatch(setDomainForNotification({}));
      dispatch(setDomainsByClient([]));
    }
  } catch (error) {
    dispatch(setDomain({}));
    dispatch(setDomainForNotification({}));
    dispatch(setDomainsByClient([]));
  }
};

const processTimelineData = (
  timeline,
  dataArray,
  objects,
  flags,
  dates,
  nextTime
) => {
  let updatedData = [...dataArray];
  const { firstObject, lastObject } = objects;
  const { isNotSameHour, isNotSameDateOrMonth } = flags;
  const { diffDays, lastDate, lastTime } = dates;
  switch (timeline) {
    case DURATIONS.HOURS_12:
    case DURATIONS.HOURS_24:
      updatedData = [...dataArray, lastObject];
      if (isNotSameHour) updatedData.splice(0, 0, firstObject);
      break;
    case 'custom':
      if (diffDays < 2) {
        nextTime = lastTime?.add(1, 'hour').format('YYYY-MM-DDTHH:mm:ssZ');

        const lastObject = {
          total_count: 0,
          attacks: 0,
          legit: 0,
          start_time: lastDate,
          end_time: nextTime,
        };
        updatedData = [firstObject, ...dataArray, lastObject];
      } else if (isNotSameDateOrMonth) {
        updatedData = [firstObject, ...dataArray];
      } else {
        updatedData = [...dataArray];
      }
      break;

    case DURATIONS.WEEK:
    case DURATIONS.MONTH:
      if (isNotSameDateOrMonth) {
        updatedData?.splice(0, 0, firstObject);
      }
      break;
    default:
      updatedData = [...dataArray];
  }
  return updatedData;
};

const initializeBoundaryObjects = (firstObjStartTime, lastDate, nextTime) => {
  const firstObject = {
    total_count: 0,
    attacks: 0,
    legit: 0,
    start_time: firstObjStartTime,
    end_time: firstObjStartTime,
  };
  const lastObject = {
    total_count: 0,
    attacks: 0,
    legit: 0,
    start_time: lastDate,
    end_time: nextTime,
  };
  return { firstObject, lastObject };
};

export const attackTrendLineChartApi = debounce(
  async (dispatch, domainId, startAndEndDateTime, timeline) => {
    dispatch(setAttackTrendlineLoader(true));
    const ATTACKS_TRENDLINE_ENDPOINT = getAttacksTrendlineEndpoint(
      domainId,
      startAndEndDateTime,
      timeline
    );

    try {
      const response: any = await request.get(
        `${getSessionStorageItem(
          CONSTANTS.GATEWAY_URL
        )}${ATTACKS_TRENDLINE_ENDPOINT}`
      );

      if (!response?.data || response?.data?.length === 0) {
        return null;
      }

      const diffDays = getDaysDifference(
        startAndEndDateTime?.from,
        startAndEndDateTime?.to
      );

      const lastDate = response?.data[response?.data?.length - 1]?.end_time;
      if (!lastDate) return null;

      const lastTime = dayjs(lastDate);
      const firstObjStartTime = response?.data?.[0]?.start_time;
      const firstObjEndTime = response?.data?.[0]?.end_time;
      if (!firstObjStartTime || !firstObjEndTime) {
        return null;
      }

      const startTime = dayjs(firstObjStartTime);
      const endTime = dayjs(firstObjEndTime);

      const isNotSameHour = startTime?.hour() !== endTime?.hour();
      const isNotSameDateOrMonth =
        startTime?.date() !== endTime?.date() ||
        startTime?.month() !== endTime?.month();
      let nextTime;
      const dataArray = response?.data ?? [];
      const { firstObject, lastObject } = initializeBoundaryObjects(
        firstObjStartTime,
        lastDate,
        nextTime
      );

      const objects = { firstObject, lastObject };
      const flags = { isNotSameHour, isNotSameDateOrMonth };
      const dates = { diffDays, lastDate, lastTime };

      const updatedData = processTimelineData(
        timeline,
        dataArray,
        objects,
        flags,
        dates,
        nextTime
      );
      if (response?.success) {
        dispatch(setAttacksTrendlineData(updatedData));
      }
    } catch (error) {
      return;
    } finally {
      dispatch(setAttackTrendlineLoader(false));
    }
  },
  600
);

export const attacksInformationApi = debounce(
  async (
    dispatch,
    page,
    domainId,
    startAndEndDateTime,
    searchTerm,
    countriesFilter,
    severitiesFilter
  ) => {
    dispatch(setAttacksInformationLoader(true));
    const countryFilter =
      countriesFilter?.length > 0 ? `&country_filter=${countriesFilter}` : '';
    const severityFilter =
      severitiesFilter?.length > 0
        ? `&severity_filter=${severitiesFilter}`
        : '';

    const ATTACKS_INFORMATION_ENDPOINT = getAttacksInformationEndpoint(
      domainId,
      startAndEndDateTime,
      page,
      searchTerm
    );

    try {
      const response: any = await request.get(
        `${getSessionStorageItem(
          CONSTANTS.GATEWAY_URL
        )}${ATTACKS_INFORMATION_ENDPOINT}${countryFilter}${severityFilter}`
      );

      if (response?.success) {
        dispatch(
          setAttackInformationData({
            data: response?.data?.attack_information_details,
            total: response?.data?.total,
          })
        );
      }
    } catch (error) {
      return null;
    } finally {
      dispatch(setAttacksInformationLoader(false));
    }
  },
  800
);

export const viewMoreAttackInfoApi = async (
  domainId,
  uniqueId,
  startAndEndDate
) => {
  const VIEW_ATTACK_DETAILS_ENDPOINT = getViewDetailsEndpoint(
    domainId,
    uniqueId,
    startAndEndDate
  );

  try {
    const response: any = await request.get(
      `${getSessionStorageItem(
        CONSTANTS.GATEWAY_URL
      )}${VIEW_ATTACK_DETAILS_ENDPOINT}`
    );

    if (response?.success) {
      return response;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const combinedResponsesApi = debounce(
  async (dispatch, domainId, startAndEndDateTime) => {
    dispatch(setCombinedResponseLoader(true));

    const COMBINED_API_ENDPOINT = getCombinedResponseEndpoint(
      domainId,
      startAndEndDateTime
    );

    try {
      dispatch(
        setCombinedResponse({
          ...initialUiState?.combinedResponse,
        })
      );

      const response: any = await request.get(
        `${getSessionStorageItem(
          CONSTANTS.GATEWAY_URL
        )}${COMBINED_API_ENDPOINT}`
      );

      if (response?.success) {
        const updatedCardsData = initialStatsData?.map((stat) => {
          const apiData = response?.data?.cards_data?.find(
            (item) => item[stat?.id] !== undefined
          );
          if (apiData) {
            return {
              ...stat,
              value: apiData[stat?.id],
              isLoading: false,
            };
          }
          return stat;
        });

        const updatedGeoMapData = {
          legit: convertKeysToCamelCase(response?.data?.geo_map_data?.legit),
          attack: convertKeysToCamelCase(response?.data?.geo_map_data?.attack),
        };

        const updatedSeverityData = {
          totalCount: response?.data?.severity_details?.total_count,
          attacksBySeverity: SEVERITY_LEVELS?.map((severity) => {
            const responseItem =
              response?.data?.severity_details?.severity_details?.find(
                (resItem: any) => resItem?.name === severity
              );
            return responseItem || { value: 0, name: severity, percentage: 0 };
          }),
        };

        let updatedAttackByCategory = [];

        if (
          response?.data?.attacks_by_category_data !== null &&
          response?.data?.attacks_by_category_data !== undefined &&
          Array?.isArray(response?.data?.attacks_by_category_data)
        ) {
          updatedAttackByCategory = response?.data?.attacks_by_category_data;
        }

        const updatedRequestStats = {
          responseCodes: convertIntoArray(response?.data?.top_response_codes),
          owaspTop10Mapping: convertIntoArray(response?.data?.owasp_top_10),
          userAgents: convertIntoArray(response?.data?.top_user_agents),
          uriHits: convertIntoArray(response?.data?.top_uris),
          requestMethods: convertIntoArray(response?.data?.top_request_methods),
        };

        const combinedResponseData = {
          cardsData: updatedCardsData,
          geoMapData: updatedGeoMapData,
          severityDetails: updatedSeverityData,
          attacksByCategoryData: updatedAttackByCategory,
          requestStats: updatedRequestStats,
        };

        dispatch(setCombinedResponse(combinedResponseData));
      } else {
        dispatch(
          setCombinedResponse({
            ...initialUiState?.combinedResponse,
            cardsData: initialStatsData?.map((stat) => {
              return {
                ...stat,
                isLoading: false,
              };
            }),
          })
        );
      }
    } catch (error) {
      dispatch(
        setCombinedResponse({
          ...initialUiState?.combinedResponse,
          cardsData: initialStatsData?.map((stat) => {
            return {
              ...stat,
              isLoading: false,
            };
          }),
        })
      );
    } finally {
      dispatch(setCombinedResponseLoader(false));
    }
  },
  100
);

export const ssEventStreamApi = async (dispatch, domainId) => {
  const SSE_ENDPOINT = `${getSessionStorageItem(
    CONSTANTS.GATEWAY_URL
  )}${ATTACK_NOTIFICATION_ENDPOINT}/${domainId}`;
  const token = getSessionStorageItem(CONSTANTS.REACT_TOKEN);
  const response = await fetch(SSE_ENDPOINT, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'text/event-stream',
      'Cache-Control': 'no-cache',
      Connection: 'keep-alive',
    },
  });

  const reader = response?.body?.getReader();
  const decoder = new TextDecoder();

  let buffer = '';

  const listeners: { [key: string]: ((event: MessageEvent) => void)[] } = {
    message: [],
  };

  const processChunk = (chunk: string) => {
    buffer += chunk;
    const events = buffer?.split('\n\n');

    while (events?.length > 1) {
      const eventText = events?.shift();
      const dataMatch = eventText?.match(/data: (.+)/);

      if (dataMatch) {
        const event: any = new MessageEvent('message', {
          data: dataMatch[1],
        });

        listeners?.message?.forEach((listener) => listener(event));
      }
    }

    buffer = events?.[0] || '';
  };

  const startReading = async () => {
    if (!reader) return;

    try {
      const { done, value } = await reader?.read();

      if (done) {
        return;
      }

      const chunk = decoder?.decode(value);
      handleErrorNotification(dispatch, chunk, {
        vertical: 'top',
        horizontal: 'right',
      });

      processChunk(chunk);

      await startReading();
    } catch (error) {
      return error;
    }
  };

  startReading();

  return {
    addEventListener: (
      type: string,
      callback: (event: MessageEvent) => void
    ) => {
      if (!listeners?.[type]) {
        listeners[type] = [];
      }
      listeners?.[type]?.push(callback);
    },
    close: () => {
      reader?.cancel();
    },
  };
};

export const getNotificationsList = async (
  dispatch: any,
  domainId,
  startAndEndDateTime,
  limit,
  page, setLoading
) => {
  const isUTCTime = isValidUTCFormat(startAndEndDateTime?.from);

  const startAndEnd = isUTCTime
    ? {
        from: convertToISTFormat(startAndEndDateTime?.from),
        to: convertToISTFormat(startAndEndDateTime?.to),
      }
    : startAndEndDateTime;

  const NOTIFICATIONS_LIST_ENDPOINT = getNotificationsListEndpoint(
    domainId,
    startAndEnd,
    limit,
    page
  );
  try {
    setLoading(true);
    const response: any = await request.get(
      `${getSessionStorageItem(
        CONSTANTS.GATEWAY_URL
      )}${NOTIFICATIONS_LIST_ENDPOINT}`
    );
    dispatch(setNotificationsData(response?.data));
    setLoading(false);
  } catch (error) {
    dispatch(setNotificationsData({}));
  }
};
