import React, { useEffect, useState } from 'react';
import { Box, Modal, Grid, Breadcrumbs, Typography } from '@mui/material';
import { makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import colors from '../../theme/colors.json';
import ViewMoreDetailsDrawer from '../dashboard/attackInformation/ViewMoreDetailsDrawer';
import CONSTANTS from '../../constants/constants';
import {
  getNotificationsList,
  viewMoreAttackInfoApi,
} from '../../services/dashboardApis';

import { ReactComponent as AlertIcon } from '../../assets/icons/svg/alert.svg';
import CustomPagination from '../../components/common/customPagination';
import { getTotalPages } from '../utils/processData';
import { getSessionStorageItem, setSessionStorageItem } from '../../encrypt';
import { calculateRange } from '../utils/convertTime';
import DateFilterPopover from './DateFilter';
import CustomButton from '../../components/common/customButton';
import DomainControl from '../dashboard/DomainControl';
import { setDomainForNotification } from '../../redux/domainData/domainActions';
import NoAttacksFound from '../NoAttacksFound';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '97%',
    paddingLeft: '3.5rem',
    paddingTop: '1rem',
    paddingBottom: '2%',
  },
  alert: {
    backgroundColor: `${colors.WHITE} !important`,
    color: `${colors.TX_COLOR_4} !important`,
    borderRadius: '0.5rem',
    padding: '0.8rem',
    cursor: 'pointer',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1) !important',
    fontWeight: 'bold',
    margin: '0.5rem',
    '& .MuiAlert-icon': {
      display: 'none',
    },
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },

  alertContent: {
    width: '100%',
    padding: '1rem',
  },
  alertMessage: {
    fontWeight: 'lighter',
    maxWidth: '95%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',

    [theme.breakpoints.up('md')]: {
      overflow: 'visible',
      textOverflow: 'initial',
      whiteSpace: 'normal',
    },
  },
  noNotificationsAlert: {
    backgroundColor: `${colors.WHITE} !important`,
    color: `${colors.TX_COLOR_4} !important`,
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    fontWeight: 'bold',
    '& .MuiAlert-icon': {
      display: 'none',
    },
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '1rem',
    gap: '10px',
  },
  pageNumberText: {
    fontSize: '1rem',
    fontWeight: 'bold',
    color: `${colors.BRIGHT_BLUE} !important`,
  },
  paginationButton: {
    textTransform: 'capitalize',
    color: `${colors.BRIGHT_BLUE} !important`,
  },
  timestamp: {
    color: colors.BG_COLOR_5,
    fontWeight: 'lighter',
    [theme.breakpoints.down('md')]: {
      marginLeft: '0',
    },
  },
  breadcrumb: {
    paddingLeft: '0.6rem',
  },
  noDataBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '60vh',
  },
  loadingBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    paddingTop: '2rem',
  },
  icon: {
    fontSize: '1.2rem',
    marginTop: '0.3rem',
  },
  linkText: {
    fontSize: '1rem',
    color: '#333',
  },
  activeText: {
    color: '#aaa',
    fontSize: '1rem',
  },
  notificationHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  filterBox: {
    width: '40%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

const NotificationsList = () => {
  const dispatch = useDispatch();
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [popoverAnchor, setPopoverAnchor] = useState<HTMLElement | null>(null);
  const [startAndEndDateFilter, setStartAndEndDateFilter] = useState(calculateRange('24h'))

  const pageSize = 10;

  const { domainForNotification } = useSelector(
    (state: any) => state?.domainData
  );

  const domainNotificationInStorage: any = getSessionStorageItem(
    CONSTANTS.DOMAIN_OBJ_NOTIFICATION
  );

  const storedDomainNotification = domainNotificationInStorage
    ? JSON.parse(domainNotificationInStorage)
    : domainForNotification;

  const { notificationsData } = useSelector(
    (state: any) => state?.notification
  );

  const modeLabel =
    storedDomainNotification?.current_mode === CONSTANTS.MODE_ON
      ? CONSTANTS.BLOCK_MODE
      : CONSTANTS.TRANSPARENT_MODE;

  const handleClickAttackAlert = async (domainId, uniqueId) => {
    const rowData: any = await viewMoreAttackInfoApi(
      domainId,
      uniqueId,
      startAndEndDateFilter
    );
    setSelectedRow(rowData);
    setDrawerOpen(true);
  };

  const handleCloseMoreInfo = () => {
    setDrawerOpen(false);
  };

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setPopoverAnchor(event?.currentTarget);
  };

  const handleClosePopover = () => {
    setPopoverAnchor(null);
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
    if (storedDomainNotification?.domain_id) {
      getNotificationsList(
        dispatch,
        storedDomainNotification?.domain_id,
        startAndEndDateFilter,
        pageSize,
        value, setLoading
      );
    }
  };
  const handleNotificationDomainChange = (selectedDomain) => {
    setSessionStorageItem(CONSTANTS.DOMAIN_OBJ_NOTIFICATION, selectedDomain);
    dispatch(setDomainForNotification(JSON.parse(selectedDomain)));
  };

  useEffect(() => {
    // setLoading(true);
    if (storedDomainNotification?.domain_id) {
      getNotificationsList(
        dispatch,
        storedDomainNotification?.domain_id,
        startAndEndDateFilter,
        pageSize,
        page, setLoading
      );
    }
    // setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storedDomainNotification?.domain_id, drawerOpen, startAndEndDateFilter]);

  const classes = useStyles();
  const totalPages = getTotalPages(notificationsData?.total, pageSize);

  if (loading) {
    return (
      <Box data-testid="refresh-data-loader" className={classes.loadingBox}>
        <div className="refresh-data-loader"></div>
      </Box>
    );
  }

  const filterStyles = {
    height: '2.7rem',
  };
  return (
    <Box className={classes.root}>
      <Box className={classes.notificationHeader}>
        <Breadcrumbs
          className={classes.breadcrumb}
          aria-label="breadcrumb"
          separator="/"
        >
          <Typography component="div">
            <AlertIcon className={classes.icon} />
          </Typography>
          <Typography component="div">Notifications</Typography>
        </Breadcrumbs>
        <Box className={classes.filterBox}>
          <Box width="70%">
            <DomainControl
              id="notificationsDomainSelect"
              defaultDomain={storedDomainNotification}
              sessionStorageKey={CONSTANTS.DOMAIN_OBJ_NOTIFICATION}
              setPage={setPage}
              onDomainChange={handleNotificationDomainChange}
              isDurationPicker={false}
            />
          </Box>
          <CustomButton
            id={'dateFilterBtn'}
            onClick={handleOpenPopover}
            label={CONSTANTS.DATE_FILTER_BTN_TEXT}
            sx={filterStyles}
          />
          <DateFilterPopover
            id="dateFilterPopover"
            anchorEl={popoverAnchor}
            open={Boolean(popoverAnchor)}
            onClose={handleClosePopover}
            setStartAndEndDateFilter={setStartAndEndDateFilter}
            setPage={setPage}
          />
        </Box>
      </Box>
      <Box>
        {notificationsData?.total === 0 ? (
          <Box
            data-testid="noNotificationsFoundBox"
            className={classes.noDataBox}
          >
            <NoAttacksFound id="noNotificationsFound" />
          </Box>
        ) : (
          notificationsData?.notifications?.map((notification) => (
            <Grid
              container
              onClick={() =>
                handleClickAttackAlert(
                  storedDomainNotification?.domain_id,
                  notification?.unique_id
                )
              }
              key={notification?.unique_id}
              className={classes.alert}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid
                item
                xs={12}
                md={9}
                title={notification?.alert_message}
                className={classes.alertMessage}
                fontWeight={notification?.read_status ? '' : 'bold'}
              >
                {notification?.alert_message}
              </Grid>
              <Grid item xs={12} md={2.5} className={classes.timestamp}>
                {notification?.timestamp}
              </Grid>
            </Grid>
          ))
        )}
      </Box>
      <Box className={classes.paginationContainer}>
        <CustomPagination
          totalPages={totalPages}
          page={page}
          handleChangePage={handlePageChange}
        />
      </Box>

      <Modal
        open={drawerOpen}
        onClose={handleCloseMoreInfo}
        aria-labelledby="attack-alert-drawer"
        aria-describedby="attack-alert-drawer"
      >
        <ViewMoreDetailsDrawer
          open={drawerOpen}
          onClose={handleCloseMoreInfo}
          selectedRow={selectedRow}
          modeLabel={modeLabel}
        />
      </Modal>
    </Box>
  );
};

export default NotificationsList;
