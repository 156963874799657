/* eslint-disable prettier/prettier */
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Dashboard from '../../screen/dashboard';
import NoPageFound from '../../screen/NoPageFound';
import ProfilePage from '../../screen/profile';
import AllClientsList from '../../screen/WafAdmin/Client/ClientList';
import Users from '../../screen/WafAdmin/User/Users';
import Logout from '../../screen/Logout';
import CONSTANTS, { MENU_PATHS } from '../../constants/constants';
import CRSRulesDashboard from '../../screen/WafAdmin/coreRuleSet/CRSRulesDashboard';
import AttackNotificationsPage from '../../screen/notifications';

export const Navigator: React.FC = () => {
  return (
    <Routes>
      <Route
        path={`${CONSTANTS.NAVIGATE_TO_DASHBOARD}`}
        element={<Dashboard />}
      />
      <Route
        path={`${CONSTANTS.NAVIGATE_TO_DASHBOARD}${MENU_PATHS.CORE_RULE_SET}`}
        element={<CRSRulesDashboard />}
      />
      <Route
        path={`${CONSTANTS.NAVIGATE_TO_DASHBOARD}${MENU_PATHS.USER_PROFILE}`}
        element={<ProfilePage />}
      />
      <Route
        path={`${CONSTANTS.NAVIGATE_TO_DASHBOARD}${MENU_PATHS.USERS}`}
        element={<Users />}
      />

      <Route
        path={`${CONSTANTS.NAVIGATE_TO_DASHBOARD}${MENU_PATHS.CLIENTS}`}
        element={<AllClientsList />}
      />
      <Route
        path={`${CONSTANTS.NAVIGATE_TO_DASHBOARD}${MENU_PATHS.NOTIFICATIONS}`}
        element={<AttackNotificationsPage />}
      />
      <Route
        path={`${CONSTANTS.NAVIGATE_TO_DASHBOARD}${process.env.REACT_APP_REALM}`}
        element={<Logout />}
      />
      <Route
        path={`${CONSTANTS.NAVIGATE_TO_DASHBOARD}*`}
        element={<NoPageFound />}
      />
    </Routes>
  );
};

export default Navigator;
