import React, { useState } from 'react';
import { Popover, Grid } from '@mui/material';
import MUIDateInput from '../../components/common/customDate/DateInput';
import CustomButton from '../../components/common/customButton';
import { convertToISTFormat } from '../utils/convertTime';
import CONSTANTS from '../../constants/constants';
import { getSessionStorageItem, setSessionStorageItem } from '../../encrypt';
import { makeStyles } from '@material-ui/core';

interface DateFilterPopoverProps {
  id?: string;
  anchorEl: HTMLElement | null;
  open: boolean;
  onClose: () => void;
  setStartAndEndDateFilter?: any;
  setPage?: any
}

const useStyles = makeStyles((theme) => ({
  popoverContent: {
    width: '20rem',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
}));

const DateFilterPopover: React.FC<DateFilterPopoverProps> = ({
  anchorEl,
  id,
  open,
  onClose,
  setStartAndEndDateFilter,
  setPage
}) => {
  const [startAndEndDate, setStartAndEndDate] = useState({
    from: '',
    to: '',
  });
  const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStartAndEndDate({
      from: e?.target?.value,
      to: e?.target?.value,
    });
  };

  const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStartAndEndDate({
      ...startAndEndDate,
      to: e?.target?.value,
    });
  };

  const handleSearchByDate = () => {
    setPage(1);
    const startDate = new Date(startAndEndDate?.from);
    startDate?.setHours(0, 0, 0, 0);
    const startDateIST = convertToISTFormat(startDate);

    const endDate = new Date(startAndEndDate?.to);
    endDate?.setHours(23, 59, 59);
    const endDateIST = convertToISTFormat(endDate);
    setSessionStorageItem(
      CONSTANTS.DATE_FILTER,
      JSON.stringify({
        from: startDateIST,
        to: endDateIST,
      })
    );

    setStartAndEndDateFilter({
      from: startDateIST,
      to: endDateIST,
    });
    onClose();
  };

  const classes = useStyles();

  return (
    <Popover
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      id={id}
      data-testid={id}
    >
      <Grid item xs={12} className={classes.popoverContent}>
        <MUIDateInput
          disableDateConstraints
          label="Start Date"
          id="startDateFilter"
          value={
            startAndEndDate?.from || ''
          }
          onChange={handleStartDateChange}
        />
        <MUIDateInput
          disableDateConstraints
          label="End Date"
          id="endDateFilter"
          value={
            startAndEndDate?.to || ''
          }
          onChange={handleEndDateChange}
          required
          disabled={!startAndEndDate?.from}
          variant="outlined"
          isEndDate={true}
          startDate={startAndEndDate?.from}
        />
      </Grid>
      <Grid item xs={12} p={1}>
        <CustomButton
          id="dateFilterSubmitButton"
          onClick={handleSearchByDate}
          label="Search"
        />
      </Grid>
    </Popover>
  );
};

export default DateFilterPopover;
