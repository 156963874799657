/* eslint-disable prettier/prettier */
import React from 'react';
import * as Yup from 'yup';
import { addOrEditOrDeleteClient } from '../../../services/clientServices';
import { useDispatch } from 'react-redux';
import ReusableForm from '../../../components/common/customForm';
import {
  handleErrorNotification,
  handleSuccessNotification,
} from '../../utils/notificationHelpers';
import { convertStringIntoArray } from '../../utils/processData';
import CONSTANTS, { FORM_LABEL_NAMES, FORM_PLACEHOLDER_VALUES } from '../../../constants/constants';

const validationSchema = Yup.object({
  domain_name: Yup.string(),
  domain_url: Yup.string(),
  ip_address: Yup.string(),
  current_mode: Yup.string(),
  hosting_service_name: Yup.string(),
  dns_mapped: Yup.boolean(),
  mapped_on: Yup.string(),
  domain_emails: Yup.string(),
  email_notifications: Yup.boolean(),
});

interface EditDomainProps {
  clientId: string;
  handleGetPaginatedClients: any;
  isEditing: boolean;
  onClose: () => void;
  domainInfo: any;
}

const editDomainFields = [
  {
    name: 'domain_name',
    label: FORM_LABEL_NAMES.DOMAIN_NAME,
    type: 'text',
    placeholder: FORM_PLACEHOLDER_VALUES.DOMAIN_NAME,
    required: true,
  },
  {
    name: 'domain_url',
    label: FORM_LABEL_NAMES.DOMAIN_URL,
    type: 'text',
    placeholder: FORM_PLACEHOLDER_VALUES.DOMAIN_URL,
    required: true,
    disabled: true,
  },
  {
    name: 'ip_address',
    label: FORM_LABEL_NAMES.IP_ADDRESS,
    type: 'text',
    placeholder: FORM_PLACEHOLDER_VALUES.IP_ADDRESS,
  },
  {
    name: 'current_mode',
    label: FORM_LABEL_NAMES.CURRENT_MODE,
    type: 'text',
    placeholder: FORM_PLACEHOLDER_VALUES.TRANSPARENT_MODE,
    disabled: true,
  },
  {
    name: 'hosting_service_name',
    label: FORM_LABEL_NAMES.HOSTING_SERVICE_NAME,
    type: 'text',
    placeholder: FORM_PLACEHOLDER_VALUES.HOSTING_SERVICE,
  },
  { name: 'dns_mapped', label: 'DNS Mapped', type: 'checkbox' },
  {
    name: 'email_notifications',
    label: FORM_LABEL_NAMES.EMAIL_NOTIFICATION,
    type: 'checkbox',
  },
  {
    name: 'mapped_on',
    label: FORM_LABEL_NAMES.MAPPED_ON,
    type: 'text',
    placeholder: FORM_PLACEHOLDER_VALUES.MAPPED_ON_DATE,
    disabled: true,
  },
  {
    name: 'domain_emails',
    label: FORM_LABEL_NAMES.DOMAIN_EMAILS,
    type: 'multi-input',
    placeholder: FORM_PLACEHOLDER_VALUES.DOMAIN_EMAILS,
  },
];

const EditDomain: React.FC<EditDomainProps> = ({
  clientId,
  handleGetPaginatedClients,
  isEditing,
  onClose,
  domainInfo,
}) => {
  const initialValues = {
    domain_name: domainInfo?.domain_name,
    domain_url: domainInfo?.domain_url,
    ip_address: domainInfo?.ip_address,
    current_mode: domainInfo?.current_mode,
    hosting_service_name: domainInfo?.hosting_service_name,
    dns_mapped: domainInfo?.dns_mapped,
    mapped_on: domainInfo?.mapped_on,
    domain_emails: domainInfo?.domain_emails?.join(', '),
    email_notifications: domainInfo?.email_notifications,
  };

  const dispatch = useDispatch();

  const handleEditDomainApi = async (postData) => {
    const response: any = await addOrEditOrDeleteClient(
      'edit-domain',
      clientId,
      postData,
      domainInfo?.domain_id
    );
    if (response?.success) {
      if (handleGetPaginatedClients) {
        handleGetPaginatedClients();
      }

      handleSuccessNotification(dispatch, response?.message);
      onClose();
    } else {
      handleErrorNotification(dispatch, response?.detail?.message);
    }
  };
  const handleSubmit = (values) => {
    const editedDomainData = {
      current_mode: values?.current_mode,
      domain_name: values?.domain_name,
      dns_mapped: values?.dns_mapped,
      domain_url: values?.domain_url,
      hosting_service_name: values?.hosting_service_name,
      ip_address: values?.ip_address,
      domain_emails: convertStringIntoArray(values?.domain_emails),
      email_notifications: values?.email_notifications,
    };

    handleEditDomainApi(editedDomainData);
  };

  return (
    <ReusableForm
      id={'editDomainForm'}
      open={isEditing}
      onClose={onClose}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      fields={editDomainFields}
      title={'Edit Domain'}
      buttonLabel={'Edit'}
    />
  );
};

export default EditDomain;
