/* eslint-disable prettier/prettier */
import { makeStyles, Typography } from '@material-ui/core';
import { Box } from '@mui/material';
import React from 'react';
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Legend,
  Sector,
  Tooltip,
} from 'recharts';
import { processData } from '../../utils/processData';
import NoAttacksFound from '../../NoAttacksFound';
import useResponsiveDimensions from '../../../components/common/useResponsiveDimensions';
import colors from '../../../theme/colors.json';

const COLORS = [colors.RED, colors.ORANGE, colors.GREEN_1, colors.GRAYISH_BLUE];
const BACKGROUND_COLOR = colors.PALE_SKY_BLUE;

const useStyles = makeStyles((theme) => ({
  tooltip: {
    position: 'absolute',
    background: colors.TX_COLOR_1,
    boxShadow: '0px 1px 4px 0px #2C405A35',
    color: colors.WHITE,
    border: '1px solid #ccc',
    padding: '10px',
    pointerEvents: 'none',
    zIndex: 1000,
    borderRadius: '4px',
    maxWidth: '15rem',
    wordWrap: 'break-word',
    transform: 'translate(-60%, -80%)',
  },
  arrow: {
    width: 0,
    height: 0,
    borderLeft: `6px solid ${colors.TRANSPARENT}`,
    borderRight: `6px solid ${colors.TRANSPARENT}`,
    borderTop: `'6px solid '${colors.TX_COLOR_1}`,
    position: 'absolute',
    left: '50%',
    top: '100%',
    transform: 'translateX(-50%)',
    filter: 'drop-shadow(0 1px 0 #FFFFFF)',
  },
  chartTitle: {
    textAlign: 'left',
    marginBottom: theme.spacing(2),
    color: colors.TX_COLOR_3,
    fontSize: '1.2rem',
    fontWeight: 'bold',
  },
  legendItem: {
    fontSize: '0.85rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '0.75rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '0.8rem',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '0.85rem',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '1.2rem',
    },
  },
  container: {
    width: '100%',
    height: '90%',
    backgroundColor: colors.WHITE,
    borderRadius: '0.5rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.up('xl')]: {
      height: '86%',
    },
  },
  circleBackground: {
    fill: BACKGROUND_COLOR,
    stroke: 'none',
  },
  circleInner: {
    fill: colors.WHITE,
    stroke: 'none',
  },
  legendList: {
    listStyle: 'none',
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    margin: 0,
  },
  legendItemContainer: {
    width: '25%',
    padding: '0.4rem',
    display: 'flex',
    alignItems: 'center',
  },
  legendColorBox: {
    width: '1rem',
    borderRadius: '0.2rem',
    height: '1rem',
    marginRight: '0.4rem',
  },
  othersItemBox: {
    maxHeight: 200,
    overflowY: 'auto',
  },
  othersItem: {
    width: 'max-content',
    fontWeight: 'normal',
  },
  requestMethodStyles: {
    width: 'max-content !important',
    fontWeight: 'bold',
  },
}));

export const CustomTooltip = ({ coordinate, active, payload }) => {
  const classes = useStyles();

  if (active && payload && payload?.length) {
    const data = payload?.[0]?.payload;
    const { x, y } = coordinate;

    return (
      <div
        className={classes.tooltip}
        style={{
          left: x,
          top: y,
          backgroundColor: data?.fill,
        }}
      >
        {data?.name !== 'Others' ? (
          <Typography variant="body2" className={classes.requestMethodStyles}>
            {data?.name} - {data?.value}
          </Typography>
        ) : (
          data?.breakdown?.map((item) => {
            return (
              <div className={classes.othersItemBox} key={`${item?.name}`}>
                <Typography variant="body2" className={classes.othersItem}>
                  {item?.name} - {item?.value}
                </Typography>
              </div>
            );
          })
        )}
        <div
          className={classes.arrow}
          style={{ borderTopColor: data?.fill }}
        ></div>
      </div>
    );
  }

  return null;
};

export const renderLegend = (props) => {
  const { payload, classes } = props;
  return (
    <Box p={0}>
      <ul className={classes.legendList}>
        {payload
          ?.filter((entry) => entry?.type !== 'none')
          ?.map((entry) => {
            return (
              <li
                key={`${entry?.value}-legendItem`}
                className={classes.legendItemContainer}
              >
                <div
                  className={classes.legendColorBox}
                  style={{ backgroundColor: entry?.color }}
                ></div>
                <span className={classes.legendItem}>{`${entry?.value}`}</span>
              </li>
            );
          })}
      </ul>
    </Box>
  );
};

export const renderActiveShape = (props) => {
  const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } =
    props;
  const zoomFactor = -5;

  return (
    <svg>
      <g>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius + zoomFactor}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
      </g>
    </svg>
  );
};

export const calculateRadii = (width, height) => {
  const minDimension = Math?.min(width, height);
  const backgroundOuterRadius = minDimension * 1.2;
  const backgroundInnerRadius = backgroundOuterRadius * 0.45;

  const outerRadius = minDimension * 1.05;
  const innerRadius = outerRadius * 0.65;

  return {
    outerRadius,
    innerRadius,
    backgroundOuterRadius,
    backgroundInnerRadius,
  };
};

const HTTPRequestMethod = ({ rawData }) => {
  const { width, height } = useResponsiveDimensions();
  const {
    outerRadius,
    innerRadius,
    backgroundOuterRadius,
    backgroundInnerRadius,
  } = calculateRadii(width, height);

  const data = processData(rawData);
  const classes = useStyles();

  if (data?.length === 0) {
    return (
      <div data-testid="test-noHttpRequestMethodsFound">
        <NoAttacksFound id="noHttpRequestMethodsFound" />
      </div>
    );
  }

  return (
    <Box className={classes.container}>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart>
          <svg>
            <circle
              cx="50%"
              cy="47%"
              r={backgroundOuterRadius}
              fill={BACKGROUND_COLOR}
              stroke="none"
            />
            <circle
              cx="50%"
              cy="47%"
              r={backgroundInnerRadius}
              fill={colors.WHITE}
              stroke="none"
            />
          </svg>

          <Pie
            activeIndex={-1}
            activeShape={renderActiveShape}
            data={data}
            cx="50%"
            cy="50%"
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            fill={colors.TX_COLOR_9}
            paddingAngle={2}
            dataKey="value"
            stroke="none"
          >
            {data?.map((entry, index) => {
              return (
                <Cell
                  key={`${entry?.name}-cell`}
                  fill={COLORS[index % COLORS?.length]}
                />
              );
            })}
          </Pie>
          <Tooltip
            content={
              <CustomTooltip
                active={undefined}
                payload={undefined}
                coordinate={{ x: 0, y: 0 }}
              />
            }
          />

          <Legend
            content={(props) => renderLegend({ ...props, classes })}
            layout="horizontal"
            verticalAlign="bottom"
            align="center"
          />
        </PieChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default HTTPRequestMethod;
